module.exports = {
  metadata: {
    title: "KS Klima",
    description: "Website created with create-tpws-website",
    author: "https://pulso-media.de",
    generator: "turingpages",
  },

  tpws: {
    environments: {
      development: {
        siteUrl: "http://localhost:9000",
      },
      staging: {
        siteUrl: "https://preview.staging.ksklimaorg.pulso-media.de",
      },
      production: {
        siteUrl: "https://ks-klima.org",
      },
    },
    defaultLocale: "de",
    dynamicTemplate: "./src/templates/dynamic-template.js",
    subfont: false,
    allowHtml: true,
  },

  strapi: {
    environments: {
      development: {
        url: "http://localhost:3000",
      },
      staging: {
        url: "https://staging.ksklimaorg.pulso-media.de",
        token:
          "cb5967dfcf52de403951a05609f584b937f58966cf95b4eb61d59ff86f446139f0ab0f8a7eb2bd619d7e133225c34c585260ec7928b7e579e96e9e91122c476c8f3bb30e4eda467db81c98aa75439ebf11bc556cfb5f78a43b2ab4bfff6e60c64e11349b5858ac79234daf905f0736ec22e626759f4bd457c124ef5de5e41d46",
      },
      production: {
        url: "https://ksklimaorg.pulso-media.de",
        token:
          "cb5967dfcf52de403951a05609f584b937f58966cf95b4eb61d59ff86f446139f0ab0f8a7eb2bd619d7e133225c34c585260ec7928b7e579e96e9e91122c476c8f3bb30e4eda467db81c98aa75439ebf11bc556cfb5f78a43b2ab4bfff6e60c64e11349b5858ac79234daf905f0736ec22e626759f4bd457c124ef5de5e41d46",
      },
    },
    collectionTypes: [],
    singleTypes: [],
  },
  /* shopify: {
    url: "mystore.myshopify.com",
    password: "<<shppa_pwd>>",
    token: "<<token>>",
  }, */
};
